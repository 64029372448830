import { render, staticRenderFns } from "./shopData.vue?vue&type=template&id=54c5a4cb&scoped=true"
import script from "./shopData.vue?vue&type=script&lang=js"
export * from "./shopData.vue?vue&type=script&lang=js"
import style0 from "./shopData.vue?vue&type=style&index=0&id=54c5a4cb&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c5a4cb",
  null
  
)

export default component.exports