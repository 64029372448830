<template>
  <div class="data_show">
    <!-- 头部信息 -->
    <div class="active_title">
      <div class="tt_left">
        <h2>{{ row.name }}活动</h2>
        <p>
          活动时间：<span>{{ row.startTime }}~{{ row.endTime }}</span>
        </p>
      </div>
      <div class="tt_right">
        <span class="rest_time">数据更新于 {{ row.startTime }}</span>
        <el-button size="small" @click="goBack"> 返回</el-button>
      </div>
    </div>
    <!-- 图表展示 -->
    <el-card class="box-card">
      <h3>商品统计报表</h3>
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="商品名称">
          <el-input v-model="dataForm.name" placeholder="请输入商品名称" />
        </el-form-item>
        <el-form-item label="统计时间">
          <el-date-picker
            v-model="dataTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="activeTimeChange"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            round
            plain
            @click="queryTableList()"
            >查询
          </el-button>
          <el-button size="small" icon="el-icon-refresh-left" @click="reset"
            >重置
          </el-button>
          <el-button
            size="small"
            :loading="isExportLoading"
            :type="!dataList.length ? 'info' : ''"
            :disabled="!dataList.length"
            @click="confirmDownload()"
            icon="el-icon-download"
            >导出
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="dataList" style="width: 100%; margin-top: 20px">
        <el-table-column prop="id" label="商品ID" width="100px" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="商品名称"
          show-overflow-tooltip
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="classification" label="商品分类" align="center">
        </el-table-column>
        <el-table-column prop="inventory" label="活动库存" align="center">
        </el-table-column>
        <el-table-column
          prop="transactionUserNum"
          label="成交用户数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="transactionOrderNum"
          label="成交订单量"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="salesVolume" label="销售额" align="center">
        </el-table-column>
        <el-table-column prop="storeRevenue" label="店铺营收" align="center">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="float: right; padding: 10px 0"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="dataForm.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="dataForm.pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { export_json_to_excel } from '@/vendor/Export2Excel';
const defaultParams = {
  endTime: '',
  startTime: '',
  pageSize: 10,
  pageNo: 1,
};
export default {
  name: 'shopData',

  data() {
    return {
      dataForm: Object.assign(
        {
          activityId: this.$route.query.row.activityId,
          shopId: this.$route.query.row.shopId,
          name: null,
        },
        defaultParams
      ),
      dataList: [], //表格数据
      isExportLoading: false,
      totalPage: 0,
      dataTime: '',
      row: Object.assign({}, this.$route.query.row),
    };
  },
  created() {
    this.queryTableList();
  },
  methods: {
    //活动时间时间
    activeTimeChange(e) {
      this.orderTime = e;
      this.dataForm.startTime = e ? e[0] : null;
      this.dataForm.endTime = e ? e[1] : null;
    },
    goBack() {
      this.$router.go(-1);
    },
    // 获取数据列表
    queryTableList() {
      this.dataListLoading = true;
      this.$api.active.reqShopData(this.dataForm).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.totalPage = Number(res.data.total);
          this.$message.success('查询成功');
        } else {
          this.dataList = [];
          this.totalPage = 0;
          this.$message.error('查询失败');
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.queryTableList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.queryTableList();
    },
    // 重置
    reset() {
      this.dataForm = Object.assign({}, defaultParams);
      this.queryTableList();
    },
    //确认下载
    confirmDownload() {
      let params = JSON.parse(JSON.stringify(this.dataForm));
      params.pageSize = this.totalPage;
      this.isExportLoading = true;
      //获取下载数据
      this.$api.active.reqShopData(params).then((res) => {
        if (res.success) {
          let tHeader = [
            '商品ID',
            '商品名称',
            '商品分类',
            '活动库存',
            '成交用户数',
            '成交订单量',
            '销售额',
            '店铺营收',
          ];
          let filterVal = [
            'id',
            'name',
            'classification',
            'inventory',
            'transactionUserNum',
            'transactionOrderNum',
            'salesVolume',
            'storeRevenue',
          ];
          const data = this.formatJson(filterVal, res.data.list);
          export_json_to_excel({
            header: tHeader,
            data,
            fileName: '商品数据列表',
          });
          this.isExportLoading = false;
        }
      });
    },
  },
  created() {
    this.queryTableList();
  },
};
</script>
<style scoped lang="less">
body {
  background-color: #f0f2f5;
  color: #333;
}
.active_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  p {
    padding: 10px 0;
  }
  .rest_time {
    color: #999;
    padding-right: 15px;
  }
}
.search {
  display: flex;
  align-items: center;
  padding: 20px 0;

  p {
    padding: 20px 0;
  }
}
.data_list {
  display: flex;
  flex-wrap: wrap;
  .data_item {
    box-sizing: border-box;
    height: 135px;
    margin-right: 15px;
    width: 19.5%;
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
    span {
      color: #666;
    }
    .money {
      padding: 15px 0;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
h5,
h3 {
  color: #666;
  padding: 0 5px;
  margin-right: 15px;
  border-left: 5px solid #31aefc;
}
h3 {
  margin-bottom: 20px;
}
</style>
